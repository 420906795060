<template>
  <Map :on-marker-click="(marker) => $emit('pickup-point-select', getPickupPoint(marker.id))" :focused-marker="focusedMarker"
       size="fix" :zoom="13" width="100%" height="100%" :markers="getMapMarkers()"
  />
</template>
<script>
import Map from '../../Map'

export default {
  name: 'PickupPointMap',
  components: {
    Map
  },
  props: {
    pickupPoints: {
      type: Array,
      required: true
    },
    selectedPickupPoint: {
      default: undefined
    }
  },
  data () {
    return {
      markers: [],
      focusedMarker: undefined
    };
  },
  mounted () {
    this.markers = this.getMapMarkers();
    if (this.selectedPickupPoint) {
      this.focusedMarker = this.markers.find(marker => marker.id === this.selectedPickupPoint.id);
    }
  },
  methods: {
    getPickupPoint (markerId) {
      return this.pickupPoints.find(pp => pp.id === markerId);
    },
    getMapMarkers () {
      return this.pickupPoints
        .map((pp) => ({
          lat: pp.latitude,
          lng: pp.longitude,
          icon: {
            anchor: { x: 20, y: 40 },
            url: '../../../assets/any-pin.svg',
            width: 40,
            height: 40
          },
          id: pp.id
        }));
    }
  },
  watch: {
    selectedPickupPoint (pickupPoint) {
      if (pickupPoint?.id) {
        this.focusedMarker = this.markers.find(marker => marker.id === pickupPoint.id);
      }
    },
    pickupPoints () {
      this.markers = this.getMapMarkers();
    }
  }
};
</script>
